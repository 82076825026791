@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: Urdu;
    src: url(./fonts/MehrNastaliqWebRegular.ttf);
}

@font-face {
    font-family: Arabic;
    src: url(./fonts/Font_Attari_Quraan_Word.ttf);
}

.urdu {
    font-family: Urdu;
    direction: rtl;
}

.arabic {
    font-family: Arabic;
    direction: rtl;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Poppins;
    background-color: #fdfdf1;
}
.insh-wrapper {
    padding: 0 50px;
}
.insh-container {
    min-height: calc(100vh - 230px)
}
.insh-btn {
    background-color: #1da599;
    color: #fff;
    overflow: hidden;
    border-radius: 4px;
    width: 100px;
    height: 40px;
    cursor: pointer;

}
.insh-btn button {
    border: 0px;
    height: inherit;
    width: inherit;
    background-color: transparent;
    color: inherit;
    cursor: inherit;
}

@media (max-width: 800px) {
    .insh-wrapper {
        padding: 0 20px;
    }   
}

.teal-text {
    color: #1da599;
}
.brown-text {
    color: #B86408;
}

.hide {
    visibility: hidden;
}

.new-para {
    text-indent: 25px;
}

.reference-gap {
    text-indent: 50px;
}