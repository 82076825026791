.about-section {
    padding: 40px 0;
    width: 70%;
    margin: auto;
}

.about-header h1 {
    text-align: center;
    color: #B86408;
    color: #1da599;
}

.about-paragraph h2 {
    color: #1da599;
    color: #B86408;
    margin-top: 15px;
}
.about-paragraph li, .about-paragraph p {
    margin-top: 10px;
}
.about-paragraph ul {
    list-style-position: inside;
}
.about-paragraph a {
    color: #1da599;
    font-weight: 500;
    text-decoration: none;
}

.about-paragraph a:hover {
    color: #B86408;
}

@media (max-width: 768px){
    .about-section {
        padding: 40px 0;
        width: 80%;
        margin: auto;
    }
    
}

@media (max-width: 425px){
    .about-section {
        padding: 20px 0;
        width: 90%;
        margin: auto;
    }
    
}