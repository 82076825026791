.body-header {
    padding: 25px 0;
    position: relative;
}

.body-header h1 {
    text-align: center;
    color: #B86408;
}

.body-header p {
    text-align: center;
}

.body-content {
    display: grid;
    grid-template-columns: 300px 300px 300px;
    justify-content: center;
    grid-gap: 20px;
    padding-bottom: 30px;
}

.content-card {
    border: #B86408 1px solid;
    padding: 5px;
    border-radius: 4px;
    position: relative;
    height: 290px;
    overflow: hidden;
}

.content-card .content-card-header h4 {
    text-align: center;
    color: #B86408;
    font-size: 16pt;
}

.content-card .content-card-header p {
    font-size: 15pt;
    margin-bottom: 15px;
    color: #7d7d7d;
}

.content-link {
    position: absolute;
    bottom: 5px;
    color: #1da599;
    font-weight: 500;
    font-size: 12pt;
    cursor: pointer;
    left: 5px;
}

.content-card-body p {
    font-size: 14pt;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content-card-body a {
    text-decoration: none;
}

.content-link:hover {
    color: #B86408;
}

.content-filter {
    margin-bottom: 25px;
    text-align: center;
}

.content-filter select {
    border: #7d7d7d 1px solid;
    border-radius: 4px;
    padding: 5px;
    width: 350px;
    outline: none;
}

select.urdu {
    font-size: 12pt;
}

.no-fatwa {
    text-align: center;
    font-size: 18pt;
    vertical-align: middle;
}

@media (max-width: 996px){
    .body-content {
        grid-template-columns: 300px 300px;
    }
}

@media (max-width: 680px){
    .body-content {
        grid-template-columns: 300px;
    }
}

@media (max-width: 380px){
    .body-content {
        grid-template-columns: 95%;
    }

    .content-filter select {
        border: #7d7d7d 1px solid;
        border-radius: 4px;
        padding: 5px;
        width: 95%;
        outline: none;
    }
}

@media (max-width: 320px){
    .content-card-body p, .content-card-header p {
        font-size: 12pt;
    }

    .content-card-header h4 {
        font-size: 12pt;
    }
}