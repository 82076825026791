.insh-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #fdfdf1;
    z-index: 99999;
    border-bottom: #acacac 1px solid;
    height: 110px;
}

.insh-logo-container {
    width: 50px;
    margin-top: 10px;
    cursor: pointer;
}

.insh-logo-container img {
    width: 100%;
    height: 100%;
}
.insh-menu {
    width: 50%;
}

.insh-menu ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
}

.insh-menu ul li {
    cursor: pointer;
    color: #7d7d7d;
    font-weight: 400;
    font-size: 18px;
}

@media (max-width:1100px) {
    .insh-menu {
        width: 70%;
    }
}
@media (max-width:800px) {
    .insh-menu ul li {
        font-size: 16px;
    }
    .insh-logo-container {
        width: 60px;
    }
}
.insh-menu ul li.active {
    color:#222931;
    font-weight: 600;
}

.insh-donate-btn { 
    text-align: end;
}

@media (max-width:1100px) {
    .insh-menu {
        width: 70%;
    }
}
@media (max-width:800px) {
    .insh-menu ul li {
        font-size: 16px;
    }
    .insh-logo-container {
        width: 60px;
    }
}

@media (max-width:768px) {
    .insh-menu {
        width: 70%;
        display: none;
    }
}