.insh-footer {
    background-color: #1da599;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
}

.footer-logo-section {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}
.footer-logo-text p {
    font-size: 9pt;
}
.footer-logo {
    width: 50px;
    margin-right: 10px;
}

.footer-logo img {
    width: 100%;
    height: 100%;
}
.footer-text-section p {
    cursor: pointer;
}
