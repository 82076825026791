

.fatwa-template {
    width: 80%;
    margin: 40px auto;
    border: 1px solid black;
    padding: 20px;
    direction: rtl;
    box-shadow: 0px 0px 5px 1px;
}

.fatwa-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding-bottom: 20px;
}

.fatwa-heading,.answer-heading,.question-heading, .bayyinu {
    font-size: 18pt;
}


.fatwa-heading {
    text-align: center;
}

.fatwa-question, .fatwa-answer {
    margin-top: 20px;
    font-size: 15pt;
}


.answer-heading {
    text-align: center;
}

.fatwa-footer {
    display: flex;
    justify-content: space-between;
}

.answered-by {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fatwa-footer  {
    width: 70%;
    font-size: 16pt;
    margin: 10px auto;
}

.fatwa-stamp {
    width: 150px;
    height: 150px;
}

.fatwa-stamp img {
    width: 100%;
    height: 100%;
}

.back-to-fatawa {
    margin: auto;
    margin-bottom: 50px;
    text-align: center;
    align-self: center;
    font-size: 13pt;
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-to-fatawa a {
    color: inherit;
    text-decoration: none;
}

@media (max-width: 800px){
    .fatwa-footer {
        width: 90%;
    }
}
@media (max-width: 580px){
    .fatwa-template {
        width: 90%;
    }
    .fatwa-heading,.answer-heading,.question-heading, .bayyinu {
        font-size: 17pt;
    }
    .fatwa-question, .fatwa-answer {
        margin-top: 20px;
        font-size: 14pt;
    }
    .fatwa-footer {
        width: 100%;
        font-size: 15pt;
    }
    .fatwa-stamp {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 510px){
    .fatwa-template {
        width: 95%;
    }
}

@media (max-width: 490px){
    .fatwa-template {
        padding: 10px;
    }
    .fatwa-heading,.answer-heading,.question-heading, .bayyinu {
        font-size: 16pt;
    }
    .fatwa-question, .fatwa-answer {
        margin-top: 20px;
        font-size: 13pt;
    }
    .fatwa-footer {
        width: 100%;
        font-size: 14pt;
    }
    .fatwa-stamp {
        width: 100px;
        height: 100px;
    }
}