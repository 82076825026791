.home-heading {
    display: flex;
    justify-content: center;
    height: 450px;
    align-items: center;
    position: relative;
    z-index: 1;
}

.home-heading::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: inherit;
    height: inherit;
    background-image: url("../../../Assets/Images/feature-image.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 0.5;
}
.website-name {
    position: relative;
}
.website-name h1{
    font-size: 100px;
    color: #222931;
    text-align: center;
}

.website-name p {
    margin-top: -30px;
    text-align: center;
    font-size: 30px;
    color: #222931;
    font-weight: 300;
}

.options-header {
    width: 40px;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.options-header img {
    width: 100%;
    margin-right: 15px;
}

.insh-home-options {
    display: flex;
    padding: 15px 20px;
    height: 180px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px lavender;
}
.options-body {
    position: relative;
}

.options-body h2 {
    color: #B86408;
}

.options-body p {
    margin-bottom: 10px;
}

.body-container {
    padding: 50px;
}
.options-section {
    display: grid;
    grid-template-columns:  430px 430px;
    grid-gap: 40px;
    justify-content: center;
}

.redirection-link a {
    color: #1da599;
    text-align: end;
    text-decoration: none;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 13px;
}

.redirection-link a:hover {
    color: #B86408; 
}

@media (max-width:1100px) {
    .options-section {
        grid-template-columns:  390px 390px;
    }
}

@media (max-width:860px) {
    .options-section {
        grid-template-columns:  360px 360px;
    }
    .options-body p {
        font-size: 15px;
    }
}

@media (max-width: 780px) {
    .options-section {
        grid-template-columns:  300px 300px;
    }
    .options-header {
        width: 25px;
    }
    .options-header h2 {
        font-size: 18px;
    }
    .options-body p {
        font-size: 14px;
    }
    .home-heading {
        height: 250px;
    }
    .website-name h1 {
        font-size: 65px;
    }
    .website-name p {
        margin-top: -20px;
        font-size: 20px;
    }
    .home-heading {
        height: 250px;
    }
    .body-container {
        padding: 50px;
    }
}

@media (max-width: 670px) {
    .options-section {
        grid-template-columns:  280px 280px;
    }
}

@media (max-width:630px) {
    .options-section {
        width: 100%;
        grid-template-columns:  100%;
    }
    .body-container {
        padding: 50px 20px;
    }
}