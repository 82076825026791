.video-content-card {
    border: #B86408 1px solid;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.video-content-card .content-card-header p {
    font-size: 11pt;
    text-align: center;
    margin-bottom: 5px;
    color: #7d7d7d;
}

.content-filter {
    margin-bottom: 25px;
    text-align: center;
}

.content-filter select {
    border: #7d7d7d 1px solid;
    border-radius: 4px;
    padding: 5px;
    width: 350px;
    outline: none;
}

.no-fatwa {
    text-align: center;
    font-size: 18pt;
    vertical-align: middle;
}


@media (max-width: 320px){
.content-card-header p {
        font-size: 12pt;
    }

    .content-card-header h4 {
        font-size: 12pt;
    }
}